import { DataistToolboxPermissionSlipJourneyImageSlug } from '#img'
import {
  type Journey, JourneySchema, type Slug,
} from '#models'

import { DataistToolboxPermissionSlipQuizTaskData, DataistToolboxPermissionSlipReadingTaskData } from './WithTask.ts'

export const DataistToolboxPermissionSlipJourneySlug: Slug = 'slug-dataist-toolbox-permission-slip-journey'

export const DataistToolboxPermissionSlipJourneyData: Journey = {
  description: 'Test your logo knowledge with this quiz!',
  timeToComplete: 60 * 5000,
  heroImage: DataistToolboxPermissionSlipJourneyImageSlug,
  schema: JourneySchema,
  slug: DataistToolboxPermissionSlipJourneySlug,
  releaseDate: new Date('Mon Nov 18 2024 05:00:00 GMT+0000').getTime(),
  tasks: [DataistToolboxPermissionSlipReadingTaskData, DataistToolboxPermissionSlipQuizTaskData],
  taskTypes: ['quiz'],
  title: 'Dataist Toolbox: Permission Slip',
  topics: ['Play-to-Earn', 'Dataist Products'],
}
