import { GenericHeroJourneyImageSlug } from '#img'
import {
  DataistPersonaJourneyData, DataistToolboxCoinJourneyData, DataistToolboxPermissionSlipJourneyData,
  LogoMemoryJourneyData, PillarsOfDataismJourneyData,
  TwoFactorAuthenticationBasicsJourneyData,
} from '#lib'
import { type Journey } from '#models'

import { GenericJourneyData } from './WithJourney.ts'

export const JOURNEY_LEVELS = [1, 2, 3]

export const generateLevel1Journeys = (): Journey[] => {
  const journeysToReturn: Journey[] = [
    DataistPersonaJourneyData,
    PillarsOfDataismJourneyData,
    TwoFactorAuthenticationBasicsJourneyData,
    DataistToolboxCoinJourneyData,
    LogoMemoryJourneyData,
  ]

  return journeysToReturn
}

export const generateLevel2Journeys = (): Journey[] => {
  let sampleJourneys: Journey[] = []
  for (let i = 7; i < 13; i++) {
    let newJourney: Journey = {
      ...GenericJourneyData,
      releaseDate: Infinity,
      description: 'This journey has not been release yet.  Check back later.',
      timeToComplete: (60 * 5) * 1000,
      heroImage: GenericHeroJourneyImageSlug,
      title: 'Coming Soon',
      slug: `slug-coming-soon-journey-${i}`,
    }
    newJourney.heroImage = `${GenericHeroJourneyImageSlug}-${i}`
    sampleJourneys.push(newJourney)
  }

  const journeysToReturn: Journey[] = [
    DataistToolboxPermissionSlipJourneyData,
    ...sampleJourneys,
  ]

  return journeysToReturn
}

export const generateLevel3Journeys = (): Journey[] => {
  let journeysToReturn: Journey[] = []
  for (let i = 1; i < 7; i++) {
    let newJourney: Journey = {
      ...GenericJourneyData,
      releaseDate: Infinity,
      description: 'This journey has not been release yet.  Check back later.',
      timeToComplete: (60 * 5) * 1000,
      heroImage: GenericHeroJourneyImageSlug,
      title: 'Coming Soon',
      slug: `slug-coming-soon-journey-${i}`,
    }
    newJourney.heroImage = `${GenericHeroJourneyImageSlug}-${i}`
    journeysToReturn.push(newJourney)
  }

  return journeysToReturn
}

export const generateJourneys = (level = 1): Journey[] => {
  switch (level) {
    case 1: {
      return generateLevel1Journeys()
    }
    case 2: {
      return generateLevel2Journeys()
    }
    case 3: {
      return generateLevel3Journeys()
    }
    default: {
      return generateLevel2Journeys()
    }
  }
}
