import type { QuizTaskValidation, Slug } from '#models'

import { DataistToolboxCoinQuiz } from './Data.ts'

export const DataistToolboxCoinValidationSlug: Slug = 'slug-dataist-toolbox-coin-quiz-validation'

export const DataistToolboxCoinValidation: QuizTaskValidation = {
  outcomes: [{ thresholds: { min: { correct: DataistToolboxCoinQuiz.questions.length } } }],
  type: 'quiz',
  schema: 'org.dataism.journey.task.validation.quiz',
  slug: DataistToolboxCoinValidationSlug,
}
