import {
  type JourneyTask, JourneyTaskSchema, TaskResourceSchema,
} from '#models'

import { OneHundredDatum } from '../shared/index.ts'
import { DataistToolboxCoinBadge } from './Badge.ts'
import { DataistToolboxCoinQuiz } from './Data.ts'
import {
  DataistToolboxCoinQuizTaskSlug,
  DataistToolboxCoinReadingTaskSlug,
} from './Slugs.ts'
import { DataistToolboxCoinValidation } from './Validation.ts'

export const DataistToolboxCoinReadingTaskData: JourneyTask = {
  description: 'Learn about COIN First',
  metadata: ['reading'],
  resource: [{
    title: 'COIN: Transform Your Data into Real Rewards',
    url: 'https://dataism.org/blog/coin-transform-your-data-into-real-rewards',
    schema: TaskResourceSchema,
    slug: 'slug-dataist-toolbox-coin-reading',
  }],
  schema: JourneyTaskSchema,
  slug: DataistToolboxCoinReadingTaskSlug,
  timeToComplete: (60 * 2) * 1000,
  title: 'Read to Learn',
  taskSet: {
    reward: [],
    validatedRequirements: [],
  },
}

export const DataistToolboxCoinQuizTaskData: JourneyTask = {

  description: 'This quiz tests your understanding of the COIN mobile app and how it can help you earn rewards by contributing data.',
  metadata: ['quiz'],
  resource: [],
  schema: JourneyTaskSchema,
  slug: DataistToolboxCoinQuizTaskSlug,
  timeToComplete: (60 * 2) * 1000,
  title: 'COIN Quiz',
  taskSet: {
    reward: [DataistToolboxCoinBadge, OneHundredDatum],
    validatedRequirements: [{ requirement: DataistToolboxCoinQuiz, validation: DataistToolboxCoinValidation }],
  },
}
