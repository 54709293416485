import type { BadgeTaskReward } from '#models'
import { BadgeTaskRewardSchema } from '#models'

export const DataistToolboxCoinBadge: BadgeTaskReward = {
  schema: BadgeTaskRewardSchema,
  badgeId: 'dataist-toolbox-coin-badge-id',
  slug: 'slug-badge-dataist-toolbox-coin',
  rewardType: 'badge',
  title: 'Dataist Toolbox: COIN Badge',
}
