import type { QuizTaskValidation, Slug } from '#models'

import { PermissionSlipQuiz } from './Data.ts'

export const DataistToolboxPermissionSlipValidationSlug: Slug = 'slug-dataist-toolbox-permission-slip-quiz-validation'

export const DataistToolboxPermissionSlipValidation: QuizTaskValidation = {
  outcomes: [{ thresholds: { min: { correct: PermissionSlipQuiz.questions.length } } }],
  type: 'quiz',
  schema: 'org.dataism.journey.task.validation.quiz',
  slug: DataistToolboxPermissionSlipValidationSlug,
}
