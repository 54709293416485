import type { BadgeTaskReward } from '#models'
import { BadgeTaskRewardSchema } from '#models'

export const DataistToolboxPermissionSlipBadge: BadgeTaskReward = {
  schema: BadgeTaskRewardSchema,
  badgeId: 'dataist-toolbox-permission-slip-badge-id',
  slug: 'slug-badge-dataist-toolbox-permission-slip',
  rewardType: 'badge',
  title: 'Dataist Toolbox: Permission Slip Badge',
}
