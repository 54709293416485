import {
  type JourneyTask, JourneyTaskSchema, TaskResourceSchema,
} from '#models'

import { OneHundredDatum } from '../shared/index.ts'
import { DataistToolboxPermissionSlipBadge } from './Badge.ts'
import { PermissionSlipQuiz } from './Data.ts'
import {
  DataistToolboxPermissionSlipQuizTaskSlug,
  DataistToolboxPermissionSlipReadingTaskSlug,
} from './Slugs.ts'
import { DataistToolboxPermissionSlipValidation } from './Validation.ts'

export const DataistToolboxPermissionSlipReadingTaskData: JourneyTask = {
  description: 'Learn about Consumer Privacy Rights and how to take back your data with Permission Slip.',
  metadata: ['reading'],
  resource: [{
    title: 'Dataism Toolbox: Take Back Your Data with Permission Slip',
    url: 'https://dataism.org/blog/dataist-toolbox-permission-slip',
    schema: TaskResourceSchema,
    slug: 'slug-dataist-toolbox-permission-slip-reading',
  },
  {
    title: 'Learn about the California Consumer Privacy Act of 2018',
    url: 'https://oag.ca.gov/privacy/ccpa',
    schema: TaskResourceSchema,
    slug: 'slug-consumer-privacy-laws-reading',
  },
  {
    title: 'Meet Consumer Reports, the group behind Permission Slip',
    url: 'https://www.consumerreports.org/',
    schema: TaskResourceSchema,
    slug: 'slug-consumer-reports-reading',
  }],
  schema: JourneyTaskSchema,
  slug: DataistToolboxPermissionSlipReadingTaskSlug,
  timeToComplete: (60 * 2) * 1000,
  title: 'Read to Learn',
  taskSet: {
    reward: [],
    validatedRequirements: [],
  },
}

export const DataistToolboxPermissionSlipQuizTaskData: JourneyTask = {

  description: 'This quiz tests your understanding of the Permission Slip app and how it can help you.',
  metadata: ['quiz'],
  resource: [],
  schema: JourneyTaskSchema,
  slug: DataistToolboxPermissionSlipQuizTaskSlug,
  timeToComplete: (60 * 2) * 1000,
  title: 'Permission Slip Quiz',
  taskSet: {
    reward: [DataistToolboxPermissionSlipBadge, OneHundredDatum],
    validatedRequirements: [{ requirement: PermissionSlipQuiz, validation: DataistToolboxPermissionSlipValidation }],
  },
}
