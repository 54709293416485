import { DataistToolboxCoinJourneyImageSlug } from '#img'
import {
  type Journey, JourneySchema, type Slug,
} from '#models'

import { DataistToolboxCoinQuizTaskData, DataistToolboxCoinReadingTaskData } from './WithTask.ts'

export const DataistToolboxCoinJourneySlug: Slug = 'slug-dataist-toolbox-coin-journey'

export const DataistToolboxCoinJourneyData: Journey = {
  description: 'Transform your mobile device into a rewards generator with COIN',
  timeToComplete: 60 * 5000,
  heroImage: DataistToolboxCoinJourneyImageSlug,
  schema: JourneySchema,
  slug: DataistToolboxCoinJourneySlug,
  releaseDate: new Date('Fri Nov 15 2024 05:00:00 GMT+0000').getTime(),
  tasks: [DataistToolboxCoinReadingTaskData, DataistToolboxCoinQuizTaskData],
  taskTypes: ['quiz'],
  title: 'Dataist Toolbox: COIN',
  topics: ['Play-to-Earn', 'Dataist Products'],
}
