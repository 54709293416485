import { type QuizRequirement, QuizRequirementSchema } from '#models'

import { DataistToolboxCoinQuizSlug, DataistToolboxCoinQuizTaskSlug } from './Slugs.ts'

export const DataistToolboxCoinQuiz: QuizRequirement = {
  title: 'COIN: Transform Your Data into Real Rewards',
  slug: DataistToolboxCoinQuizSlug,
  task: DataistToolboxCoinQuizTaskSlug,
  type: 'quiz',
  schema: QuizRequirementSchema,
  questions: [
    {
      questionText: 'What is the primary purpose of the COIN app?',
      type: 'multiple-choice',
      options: [
        {
          name: 'To track your daily activities', slug: 'slug-track-activities', value: { correct: 0 },
        },
        {
          name: 'To empower users to earn rewards by contributing data', slug: 'slug-earn-rewards', value: { correct: 1 },
        },
        {
          name: 'To organize your schedule', slug: 'slug-organize-schedule', value: { correct: 0 },
        },
        {
          name: 'To store files securely in the cloud', slug: 'slug-store-files', value: { correct: 0 },
        },
      ],
    },
    {
      questionText: 'What type of rewards can COIN users redeem?',
      type: 'multiple-choice',
      options: [
        {
          name: 'Gift cards and digital assets like XYO tokens', slug: 'slug-gift-cards', value: { correct: 1 },
        },
        {
          name: 'Physical items only', slug: 'slug-physical-items', value: { correct: 0 },
        },
        {
          name: 'Subscription services', slug: 'slug-subscription-services', value: { correct: 0 },
        },
        {
          name: 'Social media likes and shares', slug: 'slug-social-media', value: { correct: 0 },
        },
      ],
    },
    {
      questionText: 'How can new users start earning with COIN?',
      type: 'multiple-choice',
      options: [
        {
          name: 'By setting up a crypto wallet and making a deposit', slug: 'slug-wallet-deposit', value: { correct: 0 },
        },
        {
          name: 'By downloading the app and completing tasks and challenges', slug: 'slug-download-app', value: { correct: 1 },
        },
        {
          name: 'By providing personal data to advertisers', slug: 'slug-provide-data', value: { correct: 0 },
        },
        {
          name: 'By subscribing to premium features', slug: 'slug-subscribe-premium', value: { correct: 0 },
        },
      ],
    },
    {
      questionText: 'What makes COIN different from most apps that use your data?',
      type: 'multiple-choice',
      options: [
        {
          name: 'It lets you sell your data anonymously.', slug: 'slug-sell-data-anonymously', value: { correct: 0 },
        },
        {
          name: 'It allows users to turn their data into redeemable rewards.', slug: 'slug-redeem-rewards', value: { correct: 1 },
        },
        {
          name: 'It tracks your daily habits for free.', slug: 'slug-track-habits', value: { correct: 0 },
        },
        {
          name: 'It encrypts your data for secure storage.', slug: 'slug-encrypt-data', value: { correct: 0 },
        },
      ],
    },
    {
      questionText: 'Which of the following describes COIN’s Learn & Earn feature?',
      type: 'multiple-choice',
      options: [
        {
          name: 'A feature to learn new skills through paid courses', slug: 'slug-paid-courses', value: { correct: 0 },
        },
        {
          name: 'A program that rewards users for engaging with Web3 and DePIN content', slug: 'slug-rewards-program', value: { correct: 1 },
        },
        {
          name: 'A tutorial mode for beginners', slug: 'slug-tutorial-mode', value: { correct: 0 },
        },
        {
          name: 'A monthly subscription for learning materials', slug: 'slug-monthly-subscription', value: { correct: 0 },
        },
      ],
    },
    {
      questionText: 'What is required to redeem COIN tokens for XYO?',
      type: 'multiple-choice',
      options: [
        {
          name: 'A minimum of 10,000 COIN and a supported crypto wallet', slug: 'slug-minimum-coin', value: { correct: 1 },
        },
        {
          name: 'A MetaMask account only', slug: 'slug-metamask-only', value: { correct: 0 },
        },
        {
          name: 'A direct bank transfer', slug: 'slug-bank-transfer', value: { correct: 0 },
        },
        {
          name: 'A COIN premium membership', slug: 'slug-premium-membership', value: { correct: 0 },
        },
      ],
    },
  ],
}
