import {
  googleLogo,
  mastercardLogo,
  paypalLogo, pinterestLogo, playstationLogo,
} from '#img'
import { type QuizRequirement, QuizRequirementSchema } from '#models'

import { LogoMemoryQuizSlug, LogoMemoryQuizTaskSlug } from './Slugs.ts'

export const LogoMemoryQuiz: QuizRequirement = {
  description: 'Test your knowledge of popular brand logos on the Internet and learn more about the color psychology behind logo design!',
  slug: LogoMemoryQuizSlug,
  task: LogoMemoryQuizTaskSlug,
  type: 'quiz',
  schema: QuizRequirementSchema,
  questions: [
    {
      questionText: 'Which logo represents "Google"?',
      type: 'multiple-choice',
      options: [
        {
          slug: 'slug-google',
          value: { correct: 1 },
          image: googleLogo,
        },
        {
          slug: 'slug-chrome',
          value: { correct: 0 },
          image: 'https://upload.wikimedia.org/wikipedia/commons/thumb/e/e1/Google_Chrome_icon_%28February_2022%29.svg/480px-Google_Chrome_icon_%28February_2022%29.svg.png',
        },
        {
          slug: 'slug-facebook',
          value: { correct: 0 },
          image: 'https://upload.wikimedia.org/wikipedia/commons/1/1b/Facebook_icon.svg',
        },
        {
          slug: 'slug-last-pass',
          value: { correct: 0 },
          image: 'https://cdn.icon-icons.com/icons2/2699/PNG/512/lastpass_tile_logo_icon_168330.png',
        },
      ],
    },
    {
      questionText: 'Which logo represents PayPal?',
      type: 'multiple-choice',
      options: [
        {
          slug: 'slug-paypal',
          value: { correct: 1 },
          image: paypalLogo,
        },
        {
          slug: 'slug-pinterest',
          value: { correct: 0 },
          image: pinterestLogo,
        },
        {
          slug: 'slug-playstation',
          value: { correct: 0 },
          image: playstationLogo,
        },
        {
          slug: 'slug-mastercard',
          value: { correct: 0 },
          image: mastercardLogo,
        },
      ],
    },
    {
      questionText: 'Which company is NOT owned by Alphabet?',
      type: 'multiple-choice',
      options: [
        {
          name: 'YouTube',
          slug: 'slug-youtube',
          value: { correct: 0 },
        },
        {
          name: 'Waymo',
          slug: 'slug-waymo',
          value: { correct: 0 },
        },
        {
          name: 'DeepMind',
          slug: 'slug-deepmind',
          value: { correct: 0 },
        },
        {
          name: 'Facebook',
          slug: 'slug-facebook',
          value: { correct: 1 },
        },
      ],
    },
    {
      questionText: 'What emotion is commonly associated with the color blue in logo design?',
      type: 'multiple-choice',
      options: [
        {
          name: 'Tranquility and trust',
          slug: 'slug-tranquility-trust',
          value: { correct: 1 },
        },
        {
          name: 'Passion and excitement',
          slug: 'slug-passion-excitement',
          value: { correct: 0 },
        },
        {
          name: 'Creativity and playfulness',
          slug: 'slug-creativity-playfulness',
          value: { correct: 0 },
        },
        {
          name: 'Luxury and sophistication',
          slug: 'slug-luxury-sophistication',
          value: { correct: 0 },
        },
      ],
    },
    {
      questionText: 'Where is Samsung headquartered?',
      type: 'multiple-choice',
      options: [
        {
          name: 'Seoul, South Korea',
          slug: 'slug-seoul',
          value: { correct: 1 },
        },
        {
          name: 'Beijing, China',
          slug: 'slug-beijing',
          value: { correct: 0 },
        },
        {
          name: 'Tokyo, Japan',
          slug: 'slug-tokyo',
          value: { correct: 0 },
        },
        {
          name: 'San Jose, California, USA',
          slug: 'slug-sanjose',
          value: { correct: 0 },
        },
      ],
    },
    {
      questionText: 'Where is Apple headquartered?',
      type: 'multiple-choice',
      options: [
        {
          name: 'Cupertino, California, USA',
          slug: 'slug-cupertino',
          value: { correct: 1 },
        },
        {
          name: 'Seoul, South Korea',
          slug: 'slug-seoul',
          value: { correct: 0 },
        },
        {
          name: 'London, UK',
          slug: 'slug-london',
          value: { correct: 0 },
        },
        {
          name: 'Beijing, China',
          slug: 'slug-beijing',
          value: { correct: 0 },
        },
      ],
    },
    {
      questionText: 'Where is Alibaba headquartered?',
      type: 'multiple-choice',
      options: [
        {
          name: 'Hangzhou, China',
          slug: 'slug-hangzhou',
          value: { correct: 1 },
        },
        {
          name: 'San Francisco, California, USA',
          slug: 'slug-sanfran',
          value: { correct: 0 },
        },
        {
          name: 'Seoul, South Korea',
          slug: 'slug-seoul',
          value: { correct: 0 },
        },
        {
          name: 'Munich, Germany',
          slug: 'slug-munich',
          value: { correct: 0 },
        },
      ],
    },
    {
      questionText: 'Why do fast-food companies often use the color red in their logos?',
      type: 'multiple-choice',
      options: [
        {
          name: 'To symbolize power and luxury',
          slug: 'slug-power-luxury',
          value: { correct: 0 },
        },
        {
          name: 'To stimulate appetite and grab attention',
          slug: 'slug-appetite-attention',
          value: { correct: 1 },
        },
        {
          name: 'To convey trust and reliability',
          slug: 'slug-trust-reliability',
          value: { correct: 0 },
        },
        {
          name: 'To represent peace and harmony',
          slug: 'slug-peace-harmony',
          value: { correct: 0 },
        },
      ],
    },
  ],
}
