import { LogoMemory1JourneyImageSlug } from '#img'
import {
  type Journey, JourneySchema, type Slug,
} from '#models'

import { LogoMemoryQuizTaskData, LogoMemoryReadingTaskData } from './WithTask.ts'

export const LogoMemoryJourneySlug: Slug = 'slug-logo-memory-level-1-journey'

export const LogoMemoryJourneyData: Journey = {
  description: 'Test your logo knowledge with this quiz!',
  timeToComplete: 60 * 5000,
  heroImage: LogoMemory1JourneyImageSlug,
  schema: JourneySchema,
  slug: LogoMemoryJourneySlug,
  releaseDate: new Date('Fri Nov 22 2024 05:00:00 GMT+0000').getTime(),
  tasks: [LogoMemoryReadingTaskData, LogoMemoryQuizTaskData],
  taskTypes: ['quiz'],
  title: 'Logo Memory: Level 1',
  topics: ['Memory Challenge', 'Logo Recognition'],
}
