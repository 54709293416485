import type { UserContextType } from '#contexts'
import { AreYouADataistJourneySlug } from '#lib'
import type { Journey, JourneyStatus } from '#models'

import { getAllJourneysRepository, getJourneyStatusRepository } from '../../collections/index.ts'

const findInProgressJourneys = (journeys: Journey[], userJourneys: JourneyStatus[]) => {
  let inProgressJourney: Journey | undefined

  // First, check if the journey is in progress
  for (const journey of journeys) {
    // skip the are you a dataist journey
    if (journey.slug === AreYouADataistJourneySlug) continue

    // get the user journey statuses for the current journey
    const relevantJourneyStatuses = userJourneys?.filter(userJourney => userJourney.journey === journey.slug)

    // if there are no user journey statuses, the journey is not even begun
    if (relevantJourneyStatuses?.length === 0) continue

    // get the latest journey status
    const latestJourneyStatus = relevantJourneyStatuses?.at(-1)

    // get total tasks from the journey
    const totalTasks = journey?.tasks?.length ?? 0

    // get total tasks completed from the user journey
    const completedTasks = (latestJourneyStatus?.completedTasks ?? []).length

    // if there are no tasks, the journey is probably not set up correctly
    if (totalTasks === 0) return

    // If total is less than completed, show it as the next journey
    if (totalTasks > completedTasks) {
      inProgressJourney = journey
      break
    }
  }
  return inProgressJourney
}

const findNextUnStartedJourney = (journeys: Journey[], userJourneys: JourneyStatus[]) => {
  return journeys?.find((currentJourney) => {
    // skip the are you a dataist journey
    if (currentJourney.slug === AreYouADataistJourneySlug) return false

    // get the user journey statuses for the current journey
    const relevantJourneyStatuses = userJourneys?.find(userJourney => userJourney.journey === currentJourney.slug)

    // if there are no user journey statuses, the journey is not even begun
    return !relevantJourneyStatuses
  })
}

export const getNextJourney = async (user: UserContextType) => {
  const journeyRepository = getAllJourneysRepository()
  const journeyStatusRepository = getJourneyStatusRepository(user)
  const journeys = await journeyRepository.getAll()
  const userJourneys = await journeyStatusRepository?.getAll()

  const inProgressJourney = findInProgressJourneys(journeys, userJourneys)

  // Second, check for the first journey that is not started
  let nextUnStartedJourney: Journey | undefined
  if (!inProgressJourney) {
    nextUnStartedJourney = findNextUnStartedJourney(journeys, userJourneys)
  }

  if (inProgressJourney) {
    return { journey: inProgressJourney, state: 'inProgress' }
  } else if (nextUnStartedJourney) {
    return { journey: nextUnStartedJourney, state: 'unStarted' }
  }
}
