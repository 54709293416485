/* eslint-disable import-x/no-internal-modules */
import type { Slug } from '../models/index.ts'
import generic_avatar_gen_img from './journey/generic/avatar-gen.webp'
import encrypt_img from './journey/generic/encrypt.webp'
import mind_img from './journey/generic/mind.webp'
import passwords_img from './journey/generic/passwords.webp'
import perm_img from './journey/generic/perm.webp'
import prov_img from './journey/generic/prov.webp'
import quest_img from './journey/generic/quest.webp'
import security_img from './journey/generic/security.webp'
import sovereignty_img from './journey/generic/sovereignty.webp'
import {
  CreditCard, DataistToolboxCoin, DataistToolboxPermissionSlip, Keyboard, LogoMemoryLevel1, Passwords, Phone,
  PillarsOfDataism, TwoFactorAuth, Typing, WhatKindOfDataist,
} from './journey/index.ts'
import SampleJourneyHeroImage from './sample-journey-hero.jpeg'

export const AreYouADataistJourneyImageSlug: Slug = 'slug-generic-journey-hero-image'
export const GenericHeroJourneyImageSlug: Slug = 'slug-generic-journey-hero-image'
export const DataistPersonaJourneyImageSlug: Slug = 'slug-dataist-personas-journey-hero-image'
export const LogoMemoryJourneyImageSlug: Slug = 'slug-logo-memory-journey-hero-image'
export const PillarsOfDataismJourneyImageSlug: Slug = 'slug-pillars-of-dataism-journey-hero-image'
export const TwoFactorAuthJourneyImageSlug: Slug = 'slug-two-factor-auth-journey-hero-image'
export const DataistToolboxCoinJourneyImageSlug: Slug = 'slug-dataist-toolbox-journey-hero-image'
export const DataistToolboxPermissionSlipJourneyImageSlug: Slug = 'slug-dataist-toolbox-permission-slip-journey-hero-image'
export const LogoMemory1JourneyImageSlug: Slug = 'slug-logo-memory-level-1-journey-hero-image'

export const KeyboardJourneyImageSlug: Slug = 'slug-keyboard-journey-hero-image'
export const TypingJourneyImageSlug: Slug = 'slug-typing-journey-hero-image'
export const CreditCardJourneyImageSlug: Slug = 'slug-credit-card-journey-hero-image'
export const PasswordsJourneyImageSlug: Slug = 'slug-passwords-journey-hero-image'
export const PhoneJourneyImageSlug: Slug = 'slug-phone-journey-hero-image'

export const HeroImageBySlug: Record<Slug, string> = {
  [GenericHeroJourneyImageSlug]: SampleJourneyHeroImage,
  [AreYouADataistJourneyImageSlug]: SampleJourneyHeroImage,
  [DataistPersonaJourneyImageSlug]: WhatKindOfDataist,
  [LogoMemoryJourneyImageSlug]: SampleJourneyHeroImage,
  [PillarsOfDataismJourneyImageSlug]: PillarsOfDataism,
  [TwoFactorAuthJourneyImageSlug]: TwoFactorAuth,
  [DataistToolboxCoinJourneyImageSlug]: DataistToolboxCoin,
  [DataistToolboxPermissionSlipJourneyImageSlug]: DataistToolboxPermissionSlip,
  [LogoMemory1JourneyImageSlug]: LogoMemoryLevel1,
  [KeyboardJourneyImageSlug]: Keyboard,
  [TypingJourneyImageSlug]: Typing,
  [CreditCardJourneyImageSlug]: CreditCard,
  [PasswordsJourneyImageSlug]: Passwords,
  [PhoneJourneyImageSlug]: Phone,
}

export const GenericJourneyHeroImages: string[] = [
  generic_avatar_gen_img,
  mind_img,
  perm_img,
  prov_img,
  quest_img,
  security_img,
  sovereignty_img,
  Typing,
  CreditCard,
  Passwords,
  Phone,
  passwords_img,
  encrypt_img,
]
