import type { QuizTaskValidation, Slug } from '#models'

export const LogoMemoryValidationSlug: Slug = 'slug-logo-memory-quiz-validation'

import { LogoMemoryQuiz } from './Data.ts'

export const LogoMemoryValidation: QuizTaskValidation = {
  outcomes: [{ thresholds: { min: { correct: LogoMemoryQuiz.questions.length } } }],
  type: 'quiz',
  schema: 'org.dataism.journey.task.validation.quiz',
  slug: LogoMemoryValidationSlug,
}
