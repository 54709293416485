import type { Journey } from '#models'
import { MemoryRepository } from '#repository'
import { generateJourneys, JOURNEY_LEVELS } from '#samples'

export const journeyIdPredicate = (data: Journey) => data.slug

export const JourneysCollection = 'journeys'

export const getJourneysByLevelRepository = (level = 1) => new MemoryRepository<Journey>(journeyIdPredicate, generateJourneys(level))

export const getAllJourneysRepository = () => {
  const journeys: Journey[] = []
  for (const level of JOURNEY_LEVELS) {
    journeys.push(...generateJourneys(level))
  }
  return new MemoryRepository<Journey>(journeyIdPredicate, journeys)
}
