import {
  type JourneyTask, JourneyTaskSchema, TaskResourceSchema,
} from '#models'

import { OneHundredDatum } from '../shared/index.ts'
import { LogoMemoryBadge } from './Badge.ts'
import { LogoMemoryQuiz } from './Data.ts'
import { LogoMemoryQuizTaskSlug, LogoMemoryReadingTaskSlug } from './Slugs.ts'
import { LogoMemoryValidation } from './Validation.ts'

export const LogoMemoryReadingTaskData: JourneyTask = {
  description: 'Browse logos and learn a bit more about color psychology for logos. Not all links are necessary, but we encourage you to browse all of them to learn different aspects of logos, company locations, and the size of the tech industry.',
  metadata: ['reading'],
  resource: [{
    title: 'Logo Psychology: Colors, Shapes, and Emotions',
    url: 'https://www.wix.com/blog/logo-psychology',
    schema: TaskResourceSchema,
    slug: 'slug-logo-memory-logo-psychology-reading',
  }, {
    title: 'Color Psychology and Internet Brands',
    url: 'https://nypost.com/2023/07/21/im-a-brand-expert-heres-why-google-facebook-and-twitter-have-blue-logos/',
    schema: TaskResourceSchema,
    slug: 'slug-logo-memory-color-psychology-reading',
  }, {
    title: 'Top Tech Companies Infographics',
    url: 'https://coveragely.com/blog/metrics-and-analysis/the-top-100-tech-companies-2024/',
    schema: TaskResourceSchema,
    slug: 'slug-logo-memory-top-companies-reading',
  }, {
    title: "Alphabet's Subsidiaries",
    url: 'https://www.cbinsights.com/research/report/industries-disruption-alphabet/',
    schema: TaskResourceSchema,
    slug: 'slug-logo-memory-alphabet-subsidiaries-reading',
  }],
  schema: JourneyTaskSchema,
  slug: LogoMemoryReadingTaskSlug,
  timeToComplete: (60 * 2) * 1000,
  title: 'Read to Learn',
  taskSet: {
    reward: [],
    validatedRequirements: [],
  },
}

export const LogoMemoryQuizTaskData: JourneyTask = {

  description: 'See how well you know the logos of popular brands on the Internet.',
  metadata: ['quiz'],
  resource: [],
  schema: JourneyTaskSchema,
  slug: LogoMemoryQuizTaskSlug,
  timeToComplete: (60 * 2) * 1000,
  title: 'Logo Memory Quiz',
  taskSet: {
    reward: [LogoMemoryBadge, OneHundredDatum],
    validatedRequirements: [{ requirement: LogoMemoryQuiz, validation: LogoMemoryValidation }],
  },
}
