import { type QuizRequirement, QuizRequirementSchema } from '#models'

import { DataistToolboxPermissionSlipQuizSlug, DataistToolboxPermissionSlipQuizTaskSlug } from './Slugs.ts'

export const PermissionSlipQuiz: QuizRequirement = {
  description: 'Test your knowledge about Permission Slip and Consumer Reports!',
  slug: DataistToolboxPermissionSlipQuizSlug,
  task: DataistToolboxPermissionSlipQuizTaskSlug,
  type: 'quiz',
  schema: QuizRequirementSchema,
  questions: [
    {
      questionText: 'What is the primary purpose of Permission Slip?',
      type: 'multiple-choice',
      options: [
        {
          name: 'To organize your daily schedule',
          slug: 'slug-organize-schedule',
          value: { correct: 0 },
        },
        {
          name: 'To empower users to manage their personal data',
          slug: 'slug-manage-data',
          value: { correct: 1 },
        },
        {
          name: 'To store important documents securely',
          slug: 'slug-store-documents',
          value: { correct: 0 },
        },
        {
          name: 'To create privacy policies for companies',
          slug: 'slug-create-policies',
          value: { correct: 0 },
        },
      ],
    },
    {
      questionText: 'What is one of the privacy laws that Permission Slip leverage?',
      type: 'multiple-choice',
      options: [
        {
          name: 'California Privacy Rights Act (CPRA)',
          slug: 'slug-cpra',
          value: { correct: 0 },
        },
        {
          name: 'California Consumer Privacy Act (CCPA)',
          slug: 'slug-ccpa',
          value: { correct: 1 },
        },
        {
          name: 'General Data Protection Regulation (GDPR)',
          slug: 'slug-gdpr',
          value: { correct: 0 },
        },
        {
          name: 'Personal Data Protection Act (PDPA)',
          slug: 'slug-pdpa',
          value: { correct: 0 },
        },
      ],
    },
    {
      questionText: 'What can Permission Slip help you do?',
      type: 'multiple-choice',
      options: [
        {
          name: 'Opt out of data sales',
          slug: 'slug-opt-out',
          value: { correct: 1 },
        },
        {
          name: 'Correct inaccurate information',
          slug: 'slug-correct-info',
          value: { correct: 1 },
        },
        {
          name: 'Submit tax returns',
          slug: 'slug-submit-taxes',
          value: { correct: 0 },
        },
        {
          name: 'Apply for government benefits',
          slug: 'slug-apply-benefits',
          value: { correct: 0 },
        },
      ],
    },
    {
      questionText: 'What does Permission Slip act as for its users?',
      type: 'multiple-choice',
      options: [
        {
          name: 'A personal calendar assistant',
          slug: 'slug-calendar-assistant',
          value: { correct: 0 },
        },
        {
          name: 'A cloud storage service',
          slug: 'slug-cloud-storage',
          value: { correct: 0 },
        },
        {
          name: 'An authorized agent',
          slug: 'slug-authorized-agent',
          value: { correct: 1 },
        },
        {
          name: 'A legal advisor',
          slug: 'slug-legal-advisor',
          value: { correct: 0 },
        },
      ],
    },
    {
      questionText: 'How can you improve the success rate of data requests with Permission Slip?',
      type: 'multiple-choice',
      options: [
        {
          name: 'Use a nickname instead of your full legal name',
          slug: 'slug-use-nickname',
          value: { correct: 0 },
        },
        {
          name: 'Only include one email address in your profile',
          slug: 'slug-one-email',
          value: { correct: 0 },
        },
        {
          name: 'Use your full legal name and include all email addresses',
          slug: 'slug-full-legal-name',
          value: { correct: 1 },
        },
        {
          name: 'Avoid using email altogether',
          slug: 'slug-no-email',
          value: { correct: 0 },
        },
      ],
    },
    {
      questionText: 'What does Permission Slip aim to achieve?',
      type: 'multiple-choice',
      options: [
        {
          name: 'To provide free legal representation',
          slug: 'slug-legal-representation',
          value: { correct: 0 },
        },
        {
          name: 'To humanize your data and give you control over it',
          slug: 'slug-humanize-data',
          value: { correct: 1 },
        },
        {
          name: 'To offer cloud-based storage solutions',
          slug: 'slug-cloud-solutions',
          value: { correct: 0 },
        },
        {
          name: 'To create anonymous social media profiles',
          slug: 'slug-social-media',
          value: { correct: 0 },
        },
      ],
    },
    {
      questionText: 'Who created Permission Slip?',
      type: 'multiple-choice',
      options: [
        {
          name: 'The California Privacy Protection Agency',
          slug: 'slug-cppa',
          value: { correct: 0 },
        },
        {
          name: 'Consumer Reports',
          slug: 'slug-consumer-reports',
          value: { correct: 1 },
        },
        {
          name: 'Google',
          slug: 'slug-google',
          value: { correct: 0 },
        },
        {
          name: 'Meta',
          slug: 'slug-meta',
          value: { correct: 0 },
        },
      ],
    },
    {
      questionText: 'What types of companies can you send requests to using Permission Slip?',
      type: 'multiple-choice',
      options: [
        {
          name: 'Only California-based companies',
          slug: 'slug-california-companies',
          value: { correct: 0 },
        },
        {
          name: 'Any company in the United States',
          slug: 'slug-us-companies',
          value: { correct: 1 },
        },
        {
          name: 'Only companies with GDPR compliance',
          slug: 'slug-gdpr-companies',
          value: { correct: 0 },
        },
        {
          name: 'Only companies with revenue above $10 million',
          slug: 'slug-revenue-companies',
          value: { correct: 0 },
        },
      ],
    },
    {
      questionText: 'What is one of the key principles of Dataism that Permission Slip supports?',
      type: 'multiple-choice',
      options: [
        {
          name: 'Prioritizing data collection above all else',
          slug: 'slug-data-collection',
          value: { correct: 0 },
        },
        {
          name: 'Humanizing data and ensuring user control',
          slug: 'slug-humanizing-data',
          value: { correct: 1 },
        },
        {
          name: 'Sharing all personal data freely',
          slug: 'slug-data-sharing',
          value: { correct: 0 },
        },
        {
          name: 'Erasing all digital data permanently',
          slug: 'slug-data-erasure',
          value: { correct: 0 },
        },
      ],
    },
    {
      questionText: 'What is Consumer Reports known for?',
      type: 'multiple-choice',
      options: [
        {
          name: 'Providing unbiased product reviews and ratings',
          slug: 'slug-product-reviews',
          value: { correct: 1 },
        },
        {
          name: 'Manufacturing consumer electronics',
          slug: 'slug-electronics',
          value: { correct: 0 },
        },
        {
          name: 'Operating online marketplaces',
          slug: 'slug-marketplaces',
          value: { correct: 0 },
        },
        {
          name: 'Developing cloud storage solutions',
          slug: 'slug-cloud-storage',
          value: { correct: 0 },
        },
      ],
    },
  ],
}
